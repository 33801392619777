const axios = require('axios');

export function sendImpressionsV2(data) {
	return axios.post('/bff/inspector-rating/public/sendFeedback/', data);
}

/**
 * @param object userData - Параметры о пользователе
 * @param string titleFeedback - Откуда идет вызов
 * @param string textFeedback - Отзыв пользователя
 * @param int typeReview - 1-жалобы, 2-отзывы, 3-модерация, 4-мобильное приложения, 5-безопасность, 6-другое
 * @param bool isMobile - true = mobile
 * @param string webId - берется с куки ui
 * @param string convenience - Удобно или нет (не обязательный параметр)
 * @param object userDeviceInfo - Параметры устройства юзера
 */
export default function sendImpressions({
		userData,
		titleFeedback,
		textFeedback,
		typeReview,
		typeReviewId,
		isMobile,
		webId,
		convenience = '',
		userDeviceInfo
	}) {
	let feedbackData = {
		'user': {
			'email': userData.email || 'noreply@auto.ria.com',
			'fio': userData.name,
			'phone': userData.phones.toString(),
			'user_id': userData.id,
			'web_id': webId,
		},
		'ticket': {
			'departament': 'auto',
			'title': titleFeedback,
			'body': `Отзыв: ${convenience} ${textFeedback}`,
			'type': typeReview,
			'return_ticket_id': 1,
			'complaint_type': 'Отзыв',
			'complaint_text': textFeedback,
			'user_ad_id': '',
			'ad_link': '',
			'ad_id': '',
			'complaint_phone': '',
			'user_ad_phone': '',
			'case_page': location.href,
			'complainer_info': {
				'complainer_user_id': userData.id,
				'complainer_name': userData.name,
				'complainer_phone': userData.phones.toString(),
				'complainer_email': userData.email || 'noreply@auto.ria.com'
			},
			'system': {
				'browser': userDeviceInfo.userAgent,
			},
			'device': {
				'device_type': isMobile ? 'Мобильный' : 'Десктоп',
				'app_version': userDeviceInfo.appVersion,
				'os': {
					'version': '',
					'name': userDeviceInfo.platform,
					'device': isMobile ? 'Мобильный' : 'Десктоп'
				},
				'screen': {
					'width': userDeviceInfo.screenWidth,
					'height': userDeviceInfo.screenHeight
				}
			}
		}
	};

	let mailerData = {
		data: JSON.stringify(feedbackData),
		subject: titleFeedback,
	};

	return new Promise(function (resolve, reject) {
		axios.post('/users/sendImpressions/', mailerData)
			.then((data) => {
				if (data.status !== 200) {
					reject('Feedback not delivered');
				} else {
					resolve(true);
				}
			})
			.catch((error) => {
				console.log(`function sendImpressions error: ${error}`);
			});
	});
}
