import { mapGetters } from 'vuex';
import config from './config.json';
import { sendImpressionsV2 } from '../../../../helpers/sendImpressions';

const bpm = require('@bu-auto-modules/bpm');
const { get, set } = require('../../../../helpers/cookie');

const mapping = ['cabinet', 'newStepCongratulations', 'oldStepCongratulations', 'subscribesPage', 'averagePrice', 'proAccountPage'];

export default {
  name: 'Evaluation',
  props: {
    evaluationType: {
      type: String,
    },
    moduleLogData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      stars: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
      textArea: '',
      reviewSend: false,
      selectedStar: 0,
      config: {},
      isInvalidProps: false,
    };
  },
  beforeMount() {
    if (mapping.includes(this.evaluationType)) {
      this.config = config[this.evaluationType];
    } else {
      console.error('Evaluation/index.js props evaluationType is invalid');
      this.isInvalidProps = true;
      this.$destroy();
    }
  },
  methods: {
    loggerEvaluation(logData = {}) {
      try {
        this._slonik({
          ...logData,
          ...this.moduleLogData,
          assessment: this.selectedStar,
          event_id: this.config?.events?.slonik?.id,
          form_type: this.config?.events?.slonik?.type,
          screentype: this.isDesktop ? 1 : 2,
          owner_id: this.userData?.userId,
        });
      } catch (e) {
        console.error('=== ERROR in Evaluation[loggerEvaluation] ===', e.toString());
      }
    },
    sendFeedback() {
      const userInfo = {
        email: this.userData?.userEmail || '',
        fio: this.userData?.userName || '',
        phones: this.phones,
        user_id: this.userData?.userId || '',
        ui: get('ui'),
      };

      const appealInfo = {
        titleFeedback: this.segment ? `${this.config?.mailerFeedbackTitle} (${this.segment})` : this.config?.mailerFeedbackTitle,
        textFeedback: `[Отзыв: ${Number(this.selectedStar)} ${Number(this.selectedStar) > 4 ? 'Удобно' : 'Не удобно'}] ${this.textArea}`,
        typeReview: 2,
        link: location.origin + location.pathname,
        feedbackType: this.evaluationType,
      };

      const deviceInfo = {
        userAgent: navigator.userAgent,
        isMobile: this.isMobile,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        screenWidth: screen.width,
        screenHeight: screen.height,
      };

      const rating = Number(this.selectedStar);
      const additionalParam = {
        sendToCM: Boolean(this.textArea),
        sendToBPM: Boolean(((this.textArea && rating !== 10) || rating <= 7) && (userInfo.email || userInfo.phones)),
      };

      if (this.evaluationType === 'averagePrice') {
        sendImpressionsV2({
          target: this.evaluationType,
          rating,
          textFeedback: this.textArea,
          width: screen.width,
          height: screen.height,
          langId: this.langId,
          device: this.isMobile ? 'phone' : 'desktop',
          link: location.origin + location.pathname,
        })
          .catch((error) => {
            console.error(error.message);
          });
      } else {
        bpm.sendImpressions(userInfo, appealInfo, deviceInfo, undefined, undefined, additionalParam)
          .then(() => {
            set(this.config?.cookie?.name, 1, { expires: this.config?.cookie?.expires });
            this.gaEvent(this.config?.events?.ga?.name, (Number(this.selectedStar) > 4 ? 'Good' : 'Bad'), `Review_${this.evaluationType}` === 'cabinet' ? this.segment : '');
          })
          .catch((error) => {
            console.error(error.message);
          });
      }
      this.reviewSend = true;
      this.loggerEvaluation({ message_id: 1 });
    },
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      segment: 'cabinet/segment',
      langId: 'lang/id',
    }),
    alreadyVoted() {
      return Boolean(get(this.config?.cookie?.name));
    },
    phones() {
      return this?.userData?.userPhones?.toString() || '';
    },
    titleText() {
      return this.config?.text[this.langCode]?.title || '';
    },
    queryParameterPresence() {
      const keys = Object.keys(this.config?.conditionToShow?.queryParam);
      if (keys.length) {
        const checkedQueryParam = keys.map((item) => (this.config?.conditionToShow?.queryParam[item] !== 'all'
          ? this.$route.query[item] && this.config?.conditionToShow?.queryParam[item] === this.$route.query[item]
          : true)).filter(Boolean);

        return checkedQueryParam.length === keys.length;
      }
      return true;
    },
    routerNamePresence() {
      return this.config?.conditionToShow?.routerName.includes(this.$route.name);
    },
    needToShow() {
      return this.routerNamePresence && this.queryParameterPresence;
    },
  },
  watch: {
    selectedStar() {
      this.loggerEvaluation();
    },
  },
  i18n: {
    messages: {
      ru: {
        'Напишите, что Вам понравилось, а что нет': 'Напишите, что Вам понравилось, а что нет',
        'Отправить отзыв': 'Отправить отзыв',
        'Спасибо, мы все учтем и обязательно станем лучше': 'Спасибо, мы все учтем и обязательно станем лучше',
        'Спасибо, мы стараемся для вас!': 'Спасибо, мы стараемся для вас!',
      },
      uk: {
        'Напишите, что Вам понравилось, а что нет': 'Напишіть, що Вам сподобалось, а що ні',
        'Отправить отзыв': 'Відправити відгук',
        'Спасибо, мы все учтем и обязательно станем лучше': 'Дякуємо, ми все врахуємо й обов\'язково станемо краще',
        'Спасибо, мы стараемся для вас!': 'Дякуємо, ми стараємось для вас!',
      },
    },
  },
};
